/* jobUpload.scss */

.job-container {
    padding: 20px;
    max-width: 600px; /* Reduced maximum width of the container */
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.job-form {
    display: flex;
    flex-direction: column;
}

.form-label {
    margin: 10px 0 5px;
}

.text-input,
.description-textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: background-color 0.3s, border 0.3s;
    max-width: 100%; /* Ensure the input fields take the full width of the container */
    width: 100%;
}

/* Hover effect */
.text-input:hover,
.description-textarea:hover {
    border-color: #690077;
}

.description-textarea {
    color: black;
    min-height: 80px;
}

.file-upload {
    margin: 10px 0;
}

.file-preview {
    margin: 10px 0;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
}

.file-preview-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button {
    padding: 10px;
    background-color: #690077; /* A more vibrant purple */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Hover effect for the submit button */
.submit-btn:hover {
    background-color: #5e228d; /* Darker purple on hover */
}

.feedback-message {
    margin-top: 10px;
    color: #690077;
}

.page-title {
    color: #2C3E50;
}

/* adminProfile.scss */

.container {
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
}

h1 {
    text-align: center;
    color: #2C3E50;
    margin-bottom: 20px;
    font-size: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Consistent spacing between elements */
}

.label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

.input {
    padding: 12px;
    border: 2px solid #ccc; /* Default border color */
    border-radius: 8px; /* Rounded corners */
    transition: border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover/focus effects */
    color: black;
    background-color: #f9f9f9 !important;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); /* Slight inner shadow */
}

.input:hover,
.input:focus {
    border-color: #690077; /* Change the border color on hover and focus */
    background-color: #ffffff !important;
    color: black !important;
    box-shadow: 0 0 6px rgba(105, 0, 119, 0.3); /* Glowing shadow effect */
}

.input::placeholder {
    color: #bbb;
}

.fileInput {
    padding: 8px;
    border: 1px dashed #690077;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.fileInput:hover {
    border-color: #55005c;
}

.imagePreview {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.previewImage {
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #690077;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a slight shadow to the image */
}

.submitButton {
    padding: 12px;
    background-color: #690077;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submitButton:hover {
    background-color: #55005c;
    transform: scale(1.05); /* Add a slight grow effect on hover */
}

.message {
    margin-top: 15px;
    color: #690077;
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    background-color: #f0e6f6;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .container {
        padding: 20px;
    }

    h1 {
        font-size: 1.5rem;
    }

    .input,
    .submitButton {
        font-size: 1rem;
    }
}
.circularImage {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Ensure it's a square */
    border-radius: 50%; /* Makes it circular */
    object-fit: cover; /* Ensures the image fits within the circle */
    border: 2px solid #ddd; /* Optional: Add a border for better visuals */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
  }
  
.sidebar-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header1 {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the header content */
    background-color: #690077; /* Dark purple */
    color: #000000; /* Light lavender */
    font-size: 14px; /* Set the font size for the header */
  }

  .hamburger-icon {
    position: absolute; /* Use absolute positioning for the icon */
    left: 45px; /* Position the icon on the left */
    cursor: pointer;
    font-size: 24px;
    color: #fff5f5; /* Match icon color with header text color */
  }

  .layout {
    display: flex;
    flex-grow: 1;
  }

  .sidebar {
    background-color: #690077; /* Sidebar background color */
    color: rgb(255, 255, 255);
    width: 250px;
    transition: width 0.3s ease;
    overflow: hidden;

    &.collapsed {
      
      width: 80px;

      .sidebar-menu li {
        justify-content: center;

        i {
          font-size: 32px; /* Increase icon size when collapsed */
        }
        
      }

      .sidebar-menu li span {
        display: none;
      }
    }

    .sidebar-menu {
      list-style-type: none;
      padding: 0;
      margin-top: 50px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        align-items: center;
        padding: 15px;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          color: rgb(0, 0, 0);
          background-color: #eae8ff;
        }

        i {
          font-size: 24px;
          margin-right: 10px;
        }

        span {
          font-size: 18px;
        }
      }
    }
  }
  .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f5f5f5;
    transition: margin-left 0.3s ease;
  }
}

  .header-title {
    color: rgb(255, 255, 255);
  }

/* announcementUpload.scss */

.container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.form {
    display: flex;
    flex-direction: column;
}

.label {
    margin: 10px 0 5px;
}

.input,
.textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border 0.3s; /* Transition for hover effect */
}

.input:hover,
.textarea:hover {
    border-color: #690077; /* Change border color on hover */
}

.textarea {
    min-height: 80px;
}

.fileInput {
    margin: 10px 0;
}

.filePreview {
    margin: 10px 0;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.removeButton {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitButton {
    padding: 10px;
    background-color: #690077;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.message {
    margin-top: 10px;
    color: #690077;
}

.order-return-container {
  position: relative;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f4f6f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.To {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-button {
  padding: 12px 24px;
  margin: 0 15px;
  font-size: 1rem;
  background-color: #690077;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 6px;
  
  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }

  &.active {
    background-color: #0056b3;
  }
}

.section-container {
  position: relative;
  overflow-y: auto;
  height: 400px;
  padding-right: 15px;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.section {
  display: none;
  padding: 20px;
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;

  &.active {
    display: block;
    opacity: 1;
    transform: translateX(0);
  }
}

.order-section {
  background-color: #f9f9f9;
}

.return-section {
  background-color: #e9f7ff;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.item-card {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.2s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
}

.item-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.item-details {
  font-size: 1rem;
  color: #555;
  margin: 5px 0;
}

.status-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  &.received {
    background-color: #28a745;
  }

  &.sent {
    background-color: #690077;
  }
}

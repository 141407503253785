/* newsUpload.scss */

.news-upload-container {
    padding: 30px;
    max-width: 700px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

h1 {
    text-align: center;
    color: #690077;
    margin-bottom: 20px;
    font-size: 2rem;
}

.news-upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Spacing between elements */
}

.label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

.input,
.textarea {
    padding: 12px;
    border: 2px solid #ccc;
    border-radius: 8px;
    transition: border 0.3s ease, box-shadow 0.3s ease;
    color: black;
    background-color: #f9f9f9 !important;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); /* Inner shadow for input fields */
}

.input:hover,
.input:focus,
.textarea:hover,
.textarea:focus {
    border-color: #690077;
    box-shadow: 0 0 6px rgba(105, 0, 119, 0.3);
}

.textarea {
    min-height: 150px;
}

.fileInput {
    padding: 8px;
    border: 1px dashed #690077;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
}
.videoPreview {
    margin-top: 10px;

    video.previewVideo {
      width: 400px;
      height: 250px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
.imagePreview {
    margin: 10px 0;
    display: flex;
    justify-content: center;
}

.previewImage {
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid #ccc;
}

.submitButton {
    padding: 12px;
    background-color: #690077;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submitButton:hover {
    background-color: #55005c;
    transform: scale(1.05); /* Grow slightly on hover */
}

.message {
    margin-top: 15px;
    color: #690077;
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    background-color: #f0e6f6;
    border-radius: 8px;
}

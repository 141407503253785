.comment-display-section {
    height: 500px; /* Total height for the section */
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(135deg, #ffffff, #e6f7ff);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  h2 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 15px; /* Adjusted for better spacing */
  }
  
  .no-comments-message {
    text-align: center;
    font-size: 18px;
    color: #666;
  }
  
  .comment-list {
    flex: 1; /* Takes available space in the container */
    max-height: 400px; /* Adjusted to fit within the 500px height */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-right: 10px; /* Space for scrollbar */
  }
  
  /* Styling the scrollbar */
  .comment-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .comment-list::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .comment-list::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .comment-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .comment-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .comment-card h3 {
    font-size: 18px; /* Slightly smaller for compact design */
    margin-bottom: 8px;
    color: #444;
  }
  
  .comment-card p {
    font-size: 14px; /* Slightly smaller to fit within card */
    color: #555;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  
  .comment-date {
    font-size: 12px; /* Reduced for better scaling */
    color: #888;
    text-align: right;
    display: block;
  }
  .comment-card {
    border: 1px solid #ddd;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .comment-date {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;  /* Adds space between date and buttons */
  }
  
  .comment-buttons {
    display: flex;
    gap: 10px;  /* Adds space between buttons */
    margin-top: 10px;  /* Adds space between description and buttons */
  }
  
  .post-button,
  .decline-button {
    padding: 8px 15px;  /* Slightly smaller buttons */
    font-size: 14px;  /* Slightly smaller text */
    border-radius: 4px;
    cursor: pointer;
    border: none;
    width: auto;  /* Ensures buttons have auto width based on content */
  }
  
  .post-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .decline-button {
    background-color: #f44336;
    color: white;
  }
  
  .post-button:hover {
    background-color: #45a049;
  }
  
  .decline-button:hover {
    background-color: #e53935;
  }
  
.add-employee-form-container {
  max-width: 700px;
  margin: 0px auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
}

h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #2C3E50;
}

.add-employee-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-group {
    display: flex;
    justify-content: space-between; /* Align items side by side */
    align-items: center;
    width: 100%;

    label {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 14px;
      color: #34495E;
      width: 30%; /* Adjust label width */
      text-align: left; /* Align labels to the left */
    }

    input {
      width: 65%; /* Reduced width for the input fields */
      padding: 10px 14px; /* Adjusted padding */
      border: 1px solid #ccc;
      border-radius: 8px;
      font-size: 16px;
      background-color: #f9f9f9;
      color: #2C3E50;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
        border-color: #690077;
        box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
      }
    }

    .profile-preview {
      margin-right: 0px;
      margin-bottom: 10px; // Adds spacing between the image and the file input
      max-width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 2px solid #690077;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .submit-btn {
    padding: 12px 20px;
    background-color: #690077;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 8px 16px rgba(52, 152, 219, 0.3);

    &:hover {
      background-color: #972ba5;
      transform: translateY(-3px);
      box-shadow: 0 12px 24px rgba(41, 128, 185, 0.5);
    }
  }

  input[type="file"] {
    padding: 0;
    font-size: 16px;
    background-color: #ecf0f1;
    border: none;
    border-radius: 6px;
    color: #2C3E50;
    cursor: pointer;

    &::file-selector-button {
      background-color: #690077;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #2980b9;
      }
    }
  }
}

@media (max-width: 768px) {
  .add-employee-form-container {
    padding: 20px;
  }

  h2 {
    font-size: 24px;
  }

  .submit-btn {
    font-size: 16px;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .add-employee-form-container {
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 8px 12px;
  }

  .form-group {
    flex-direction: column; /* Stack fields vertically on small screens */
    
    label {
      width: auto; /* Reset label width */
    }

    input {
      width: 100%; /* Full width for input fields */
    }
  }
}
